.all-card-content {
  margin: auto;
  margin-top: 32px;
  width: 80%;

  background: var(--pale-mauve);
  color: var(--honey);

  border-top: 4px double var(--honey);
  border-bottom: 4px double var(--honey);
  border-radius: 24px;
}

.title-edit-content a:hover,
.author a:hover {
  color: var(--peach-beach);
}

.title-edit-content {
  display: flex;
  flex-direction: column;

  margin-top: 2%;
  margin-bottom: 3%;

  align-items: center;
}
.edit-container {
  align-self: flex-end;
  margin-right: 4%;
}

.edit {
  max-width: 0.2em;
  max-height: 20px;
}
.title-edit-content h1 {
  text-align: center;
}
.title {
  font-size: 40px;
  font-family: "Inknut Antiqua", serif;
  letter-spacing: 0.01em;

  color: var(--milk-and-cookies);
}

.author-date-content {
  display: flex;
  align-items: center;
  padding: 2%;
  background: var(--dark-mauve);

  color: var(--milk-and-cookies);
}
.author,
.author-guest {
  display: flex;
  flex-basis: 50%;
  justify-content: center;

  margin: 8px;
  align-self: center;

  font-size: 30px;
}

.author a,
.author-guest {
  color: var(--milk-and-cookies);
  font-family: "Josefin Slab", serif;
  font-weight: 600;
}

.date-content {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;
}
.date-content div {
  text-align: center;

  font-size: 16px;
  font-weight: 500;
}

/* ---------------------- Tags --------------- */

.tags-content {
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 2%;

  background: var(--dark-mauve);
}
.tag {
  padding: 0.6em 0.8em;

  background: var(--pale-mauve);
  color: var(--milk-and-cookies);

  font-family: "Josefin Slab", serif;
  font-weight: 600;

  border-radius: 4px;
  border-top: 2px solid var(--honey);
  border-bottom: 2px solid var(--honey);
}
.tags-content a:hover {
  background: var(--honey);
  color: var(--sinister);
}

/* -------------------- Rating ---------------- */

.rating-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  background: var(--honey);
  height: 42px;
}

.rating-content > div {
  display: flex;
  margin: 4px;
  align-items: center;
}
.rating-icon {
  border-radius: 24px;
  max-width: 28px;
}

/* ------------------ Description -------------*/
.description-content {
  background: var(--dark-mauve);
  color: var(--milk-and-cookies);

  padding: 6%;
  padding-top: 1%;

  font-family: "Josefin Slab", serif;
  font-size: 1.3em;
  font-weight: 600;

  text-align: left;
  line-height: 1.4em;
}

.snippet {
  text-indent: 8%;
  padding-bottom: 2px;
}

.about-author :not(p:first-child) {
  text-indent: 30px;
}

/*----------------- Author Card -------------*/
.books-content {
  display: flex;
  flex-direction: column;
  padding-top: 4%;
  padding-bottom: 4%;
  /* grid-template-columns: 0.4fr 1fr; */
  align-items: center;
  background: var(--dark-mauve);
}
.books-content label {
  display: flex;

  justify-content: center;
  padding-bottom: 1%;

  font-family: "Josefin Slab", serif;
  font-size: 1.4em;
  font-weight: 600;

  color: var(--milk-and-cookies);
}
.books {
  display: flex;
  justify-content: center;
  flex-basis: 50%;
}
.book {
  display: flex;

  margin: 8px 12px;
  padding: 0.6em 0.8em;
  align-items: center;

  color: var(--milk-and-cookies);
  background: var(--pale-mauve);

  font-family: "Inknut Antiqua", serif;
  font-size: 16px;
  text-align: center;
  line-height: 1.4em;

  border-radius: 4px;
  border-top: 2px solid var(--honey);
  border-bottom: 2px solid var(--honey);
}
.book:hover {
  background: var(--honey);
  color: var(--sinister);
}

.filler {
  margin: 50px;
}
/* --------------- Guest ----------------- */
.tag-guest:hover,
.title-guest:hover,
.author-guest:hover,
.edit-guest:hover,
.delete-guest:hover {
  cursor: help;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
}

/* ------- tablet & mobile -------- */
@media only screen and (max-width: 899px) {
  .all-card-content {
    width: 100%;
    margin-bottom: 120px;
  }
  .author-date-content {
    padding: 6% 6%;
    padding-bottom: 4%;
  }
}
