.message-container {
  width: fit-content;
  margin: auto;
  margin-top: 60px;

  border-radius: 12px;
  background: var(--pale-mauve);
  padding: 40px 80px;
  color: var(--milk-and-cookies);
}
.message-header,
.link {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.link {
  width: fit-content;
  margin: auto;
  margin-top: 12px;
  padding: 12px 40px;

  border-radius: 4px;

  background: var(--dark-mauve);
  color: var(--milk-and-cookies);

  cursor: pointer;
}

.link:hover {
  background: var(--honey);
  color: var(--dark-mauve);
  text-decoration: none;
}

/* guest */
.guest-title {
  margin-top: 32px;
  padding: 24px;
  text-align: center;

  color: var(--honey);

  /* background: var(--honey); */

  border: 4px double var(--honey);
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.subtext {
  text-align: center;
  background: var(--honey);

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  padding: 1rem;
}
