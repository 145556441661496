header {
  display: flex;
  justify-content: center;
  width: 100vw;
  margin: auto;

  background: var(--moonless-night);
}

header a {
  display: flex;
  justify-content: center;

  width: 100%;
  z-index: 9999;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
}

/* ------- tablet -------- */
@media only screen and (min-width: 401px) and (max-width: 899px) {
}
/* ------- mobile -------- */
@media only screen and (max-width: 400px) {
}
