.content {
  width: 80%;
  margin: auto;

  text-align: center;
}
.heading {
  margin-top: 32px;
  padding: 24px;

  color: var(--sinister);
  background: var(--honey);

  border: 4px double var(--sinister);
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.list {
  padding: 24px;

  background: var(--cafe-creme);

  border-radius: 12px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  border: 4px double var(--sinister);
  border-top: none;
}
.list-item {
  /* padding: 16px; */
  list-style-type: none;
}
.link {
  color: var(--sinister);
  font-size: 24px;
  font-family: "Inknut Antiqua", serif;
}
.link:hover {
  padding-left: 24px;
  padding-right: 24px;

  border-radius: 12px;

  background: var(--honey);
  border: 4px double var(--sinister);
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
}

/* ------- tablet -------- */
@media only screen and (min-width: 401px) and (max-width: 899px) {
  .content {
    width: 100%;
    margin-bottom: 80px;
  }
}
/* ------- mobile -------- */
@media only screen and (max-width: 400px) {
  .content {
    width: 100%;
    margin-bottom: 80px;
  }
}
