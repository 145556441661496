@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;700&family=Josefin+Slab:ital,wght@0,300;0,600;1,400&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&display=swap");

/* 
font-family: 'Inknut Antiqua', serif;
font-family: 'Josefin Slab', serif;
font-family: 'Dosis', sans-serif;
*/

:root {
  --honey: #d69554;
  --peach-beach: #ffcf9e;
  --pale-mauve: #7b4e51;
  --dark-mauve: #513738;
  --milk-and-cookies: #ecdfdf;
  --cafe-creme: #c4967f;
  --moonless-night: #303030;
  --sinister: #16110d;

  /* Error */
  --hyper-light-drifter: #edd9d9;
  --bento-box: #ce3b3b;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;

  width: 100%;
  /* height: 100vh; */
  overflow-x: hidden;

  font-size: 18px;
  font-family: "dosis", sans-serif;

  background: var(--moonless-night);
  padding-bottom: 6rem;
}
.site-content {
  margin: auto;
}

nav a {
  background: var(--pale-mauve);
}

.active:hover {
  text-decoration: none;
}
.active {
  color: var(--sinister);
  background: var(--honey);
  border-top: none;
}

/* ------------ Design -----------*/
/* .design-button {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;

  border: 1px solid var(--milk-and-cookies);
  padding: 8px;
  color: var(--milk-and-cookies);
  background: var(--dark-mauve);

  font-family: "Inknut Antiqua", serif;
}
.design-button:hover {
  background: var(--honey);
  color: var(--sinister);
  border-color: var(--honey);
} */

h1 {
  font-family: "Inknut Antiqua", serif;
}

h1,
h2,
h3 {
  line-height: 1em;
}

a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}

.error-container {
  display: flex;
  justify-content: center;
  margin: 24px;
}

.error {
  max-width: 600px;
  padding: 24px 96px;

  border: 2px solid var(--bento-box);
  border-radius: 4px;

  background: var(--hyper-light-drifter);
  color: var(--bento-box);

  text-align: center;
  line-height: 1.6;
}

.delete-container {
  margin: auto;
  background: var(--honey);
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  align-items: center;
}
.delete {
  cursor: pointer;

  padding: 8px 96px;

  background: var(--honey);
  color: var(--sinister);

  font-weight: 700;
  font-size: 16px;

  border: 1px solid var(--honey);
  border-top: none;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}

.confirm-del-container {
  margin: 0px 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
}
.delete-message {
  text-align: center;
}

.cancel,
.delete-confirmed {
  text-align: center;
  padding: 12px 18px;
  border-radius: 12px;

  font-weight: 700;
  font-size: 16px;
  border: none;
  border-top: none;
  border-bottom: none;

  cursor: pointer;
  color: var(--sinister);
  background: var(--pale-mauve);
}

.cancel {
  border: 1px solid var(--dark-mauve);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.delete-confirmed {
  border: 1px solid var(--dark-mauve);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.cancel:hover,
.delete-confirmed:hover {
  color: var(--milk-and-cookies);
  background: var(--dark-mauve);
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
  body {
    font-size: 18px;
  }
  .site-content {
    width: 900px;
  }
}

/* ------- tablet -------- */
@media only screen and (min-width: 401px) and (max-width: 899px) {
  .site-content {
    max-width: 899px;
    min-width: 401px;
  }
}
/* ------- mobile -------- */
@media only screen and (max-width: 400px) {
  .site-content {
    width: 100%;
  }
}
