.content {
  width: 80%;
  margin: auto;
  margin-bottom: 20%;

  align-items: center;

  font-family: "Josefin Slab", serif;
  font-size: 24px;
  color: var(--milk-and-cookies);
}

.heading {
  margin-top: 32px;
  padding: 24px;

  color: var(--sinister);
  background: var(--honey);

  border: 4px double var(--dark-mauve);
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  font-size: 36px;
  text-align: center;
}

.content label,
.content legend {
  font-weight: 600;
  font-size: 0.9em;
  font-family: "Josefin Slab", serif;
  font-weight: 600;
  color: var(--milk-and-cookies);
}

.content fieldset {
  margin: 20px;
}

.content form {
  display: flex;
  flex-flow: row wrap;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  border: 4px double var(--dark-mauve);
  border-top: none;

  background: var(--pale-mauve);
  color: var(--sinister);

  margin: 0px 0px 20px 0px;
  padding: 20px 40px;
}

.form-row {
  flex-basis: 50%;
  align-items: center;

  margin-bottom: 20px;
  padding: 2px;
}

.form-row-full {
  flex-basis: 100%;
}

.content fieldset {
  display: flex;
  flex-flow: row wrap;
  border: none;
}

.content legend {
  flex: 1;
  width: 100%;
}

.content input {
  margin: 4px 0px 0px 0px;
  max-height: 24px;
  max-width: 180px;
}

.form-row:nth-of-type(odd) input {
  margin-right: 12px;
}

.form-row:nth-of-type(even) label {
  margin-left: 12px;
}

.form-row input {
  margin: 0 16px;
  padding: 13px 8px;

  text-align: left;
  font-family: "Dosis", sans-serif;
  font-size: 16px;
}
.content input,
.content select,
.content textarea {
  background: var(--milk-and-cookies);

  border: 1px solid var(--moonless-night);
  border-radius: 4px;
}
.content input[type="date"] {
  cursor: text;
  padding-top: 2px;
}

.form-row input:focus {
  outline: none !important;
  border: 1px solid #eab4b4;
}

.content textarea {
  flex: 1;

  width: 100%;
  min-height: 200px;

  margin-top: 1em;
  padding: 1em;
  border-radius: 12px;

  text-indent: 30px;
  text-align: left;
  font-size: 16px;
}

.content textarea:focus {
  border: 1px solid var(--sinister);
  border-radius: 12px;

  outline: none !important;
}
.submit {
  display: flex;
  justify-content: center;
}
.post-btn {
  padding: 8px 60px;

  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(48, 33, 33, 0.466);

  background: var(--cafe-creme);
  color: var(--sinister);

  font-family: "Inknut Antiqua", serif;
  font-weight: 600;
  font-size: 0.8em;

  cursor: pointer;
}

.post-btn:hover {
  background: var(--honey);
  color: var(--sinister);
}

/* --------------- Tags -------------------- */
.tag-holder label {
  flex: 1;
  width: 100%;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
}

.tags-container fieldset {
  display: flex;
  margin-left: 60px;
  justify-content: space-between;
}
.tags-container legend {
  margin-left: -60px;
  margin-top: 12px;
}
.tag-holder {
  display: flex;
  flex: 1 20%;
  justify-content: space-evenly;

  margin: 4px;
}

.tag-option {
  display: flex;
  align-items: center;
  padding: 4px 16px;

  border: 1px solid var(--milk-and-cookies);
  border-radius: 8px;

  color: var(--milk-and-cookies);
  text-align: center;
  font-family: "Dosis", sans-serif;
  font-size: 0.7em;

  cursor: pointer;
}

.tag-option:hover {
  background: var(--dark-mauve);
  border: 1px solid var(--dark-mauve);
}
.checked {
  background: var(--dark-mauve);
}
.tag {
  display: none;
}

/* -------------------- Rating --------------------- */
.rating-container {
  display: flex;
  margin-top: -20px;
}
.rating-container div:first-of-type {
  margin-left: 96px;
}
.rating-container div {
  margin-top: 12px;
  margin-left: 12px;
}
.rating-holder {
  font-size: 20px;
  display: flex;
  flex-direction: 20%;
  justify-content: space-between;

  margin: 4px;
}

.rating-option {
  display: flex;
  align-items: center;
  padding: 4px 16px;

  border: 1px solid var(--milk-and-cookies);
  border-radius: 8px;

  color: var(--milk-and-cookies);
  text-align: center;
  font-family: "Dosis", sans-serif;
  font-size: 0.7em;

  cursor: pointer;
}

.rating-option:hover {
  border: 1px solid var(--dark-mauve);
}

.rating {
  display: none;
}
.selected {
  background: var(--dark-mauve);
  border: 1px solid var(--dark-mauve);
}

/* ----------------- Edit Author ----------------------*/

.author-name {
  padding: 24px 16px;
  padding-bottom: 4px;

  background: var(--pale-mauve);
  border-left: 4px double var(--dark-mauve);
  border-right: 4px double var(--dark-mauve);
}

.books-written {
  border: 4px double var(--dark-mauve);
}
.books {
  border: 4px double var(--dark-mauve);
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
}

/* ------- tablet & mobile -------- */
@media only screen and (max-width: 899px) {
  .content {
    width: 100%;
  }
  .content form {
    padding: 2rem 0.8rem;
  }
}
