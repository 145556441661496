.content {
  max-width: 700px;
  margin: auto;
  padding: 24px 40px;
  margin-top: 60px;

  border-radius: 8px;
  box-shadow: 2px 2px 1px 0.5px rgba(34, 39, 29, 0.459);

  font-family: "dosis", sans-serif;
  font-size: 24px;
  color: var(--milk-and-cookies);

  background: var(--pale-mauve);
}
.content-container {
  display: flex;
  flex-direction: column;
}
.content form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content h1 {
  padding-bottom: 12px;
  font-size: 40px;
}

.content span {
  padding: 8px;
  font-size: 24px;
}

.content input {
  margin-top: 8px;
  margin-left: 32px;
  padding: 4px 2px;
  font-size: 16px;
}

.content label {
  display: flex;
  justify-content: space-between;

  margin: 16px 80px;
}

.login-signup-btn {
  margin: auto;
  margin-bottom: 32px;
  margin-top: 32px;
  padding: 8px 32px;

  border: 1px solid rgb(75, 53, 53);
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(48, 33, 33, 0.466);

  background: var(--dark-mauve);
  color: var(--milk-and-cookies);

  font-family: "Dosis", sans-serif;
  font-weight: 600;
  font-size: 0.8em;

  cursor: pointer;
}

.login-signup-btn:hover {
  background: var(--milk-and-cookies);
  color: var(--dark-mauve);
}
.unverified {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resend-btn {
  padding: 8px 60px;
  margin-top: 20px;
  width: 50%;

  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(48, 33, 33, 0.466);

  background: var(--cafe-creme);
  color: var(--sinister);

  font-family: "Inknut Antiqua", serif;
  font-weight: 600;
  font-size: 0.8em;

  cursor: pointer;
}
.resend-btn:hover {
  background: var(--honey);
  color: var(--sinister);
}
