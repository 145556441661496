.container {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;

  margin-top: 4rem;
  margin-right: 2rem;
  padding: 1rem;

  background: var(--pale-mauve);

  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.container a {
  color: var(--milk-and-cookies);
  font-size: 0.4rem;
}
.container a:hover {
  color: var(--peach-beach);
}
