.container {
  margin: 32px 80px;

  border: 2px solid var(--sinister);
  background: #adcfad;
  border-radius: 4%;
}
.title {
  display: flex;
  justify-content: center;

  padding: 12px 24px;
}
.section {
  padding: 12px 24px;
  border-top: none;
}
.section-title {
  margin-bottom: 2px;
  border-bottom: 2px solid var(--sinister);
}
.li-1,
.li-2 {
  list-style: none;
}
.li-1 {
  padding-left: 16px;
}
.li-2 {
  padding-left: 32px;
}
.colors {
  display: flex;
  flex-direction: row;
}
.color-square {
  flex-basis: 50%;
}
.color-details {
  flex-basis: 50%;
  border-bottom: 1px solid var(--sinister);
}
.color-name {
  margin-left: 32px;
}
.color-ID {
  display: flex;
  justify-content: flex-end;
}
.fonts {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  /* align-items: center; */
}
.font-name {
  display: flex;
  /* flex-basis: 50%; */
  flex-direction: column;
  justify-content: center;

  margin-bottom: 2px;
  padding: 4px 12px;

  border-bottom: 1px solid var(--sinister);
}
.font-name h1 {
  text-align: center;
}
.font-name p {
  text-indent: 10%;
}
.h1 {
  margin-top: 12px;
}
.structure-info p {
  margin: 6px 16px;
}
