.container {
  width: 80%;
  margin: auto;

  text-align: center;
}
.heading {
  margin-top: 32px;
  padding: 24px;

  color: var(--sinister);
  background: var(--honey);

  border: 4px double var(--sinister);
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 2px solid var(--sinister);
}
.content {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;

  background: var(--cafe-creme);

  border: 4px double var(--sinister);
  border-top: none;
  border-bottom: none;
}
.gap {
  padding: 7px 12px;
}
.open-section {
  background: var(--cafe-creme);
  border: 4px double var(--sinister);
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 32px 20px;
}
/* ul */
.tags ul {
  border-right: 1px solid var(--sinister);

  border-bottom-right-radius: 12px;
}
/* Tabs */
.tag-tab,
.rating-tab {
  padding: 12px;

  background: var(--pale-mauve);
  color: var(--milk-and-cookies);

  border-bottom: 2px solid var(--sinister);

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  font-family: "Josefin Slab", serif;

  cursor: pointer;
}
.open-tab {
  background: var(--honey);
  color: var(--sinister);
}
.tag-tab {
  margin-left: 140px;
}
.rating-tab {
  margin-right: 140px;
}

/* list-item */
.list-item {
  padding: 8px;

  background: var(--pale-mauve);
  color: var(--milk-and-cookies);

  border: 1px solid var(--sinister);

  font-size: 1.2em;

  list-style-type: none;
  font-family: "Josefin Slab", serif;
  font-size: 24px;
  font-weight: 600;
}
.tags .list-item:last-of-type {
  margin-bottom: 16px;
}
.ratings .list-item:last-of-type {
  margin-bottom: 16px;
}
.tags .list-item {
  margin-left: 60px;
  margin-right: 60px;

  border-radius: 10px;
  border-bottom-left-radius: 10px;
}
.ratings .list-item {
  margin-right: 60px;
  margin-left: 60px;

  border-radius: 10px;
}

/* li:hover */
.open-section li:hover {
  background: var(--honey);

  cursor: pointer;
}
.tags li:hover {
  margin-right: 12px;
  margin-left: 12px;
}
.ratings li:hover {
  margin-right: 12px;
  margin-left: 12px;
}

/* li:hover a */
/* .section li:hover a {
  text-decoration: none;
}
.section li a {
  color: var(--milk-and-cookies);
} */

.msg-select-category {
  padding: 20px;

  background: var(--cafe-creme);

  /* border: 4px double var(--sinister); */
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.link {
  color: var(--sinister);
}
.minor-heading {
  font-family: "Inknut Antiqua", serif;
  font-size: 24px;
  margin-bottom: 16px;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
}

/* ------- tablet -------- */
@media only screen and (min-width: 401px) and (max-width: 899px) {
  .container {
    width: 100%;
    margin-bottom: 80px;
  }
}
/* ------- mobile -------- */
@media only screen and (max-width: 400px) {
  .container {
    width: 100%;
    margin-bottom: 80px;
  }
}
