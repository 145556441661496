nav {
  margin: auto;

  display: flex;
  width: 96%;

  justify-content: center;

  text-align: center;
  font-family: "Josefin Slab", serif;

  font-weight: 700;
  border-top: 4px double var(--honey);
}

nav a {
  font-size: 1.4rem;
  color: var(--milk-and-cookies);
}

nav ul {
  display: flex;
  justify-content: space-between;
  width: 92%;

  list-style-type: none;
}

.create-tab {
  display: flex;
  align-items: center;
}

.middle {
  display: flex;
}
.middle li:nth-child(even) a {
  border-left: 1px solid var(--honey);
  border-right: 1px solid var(--honey);
}

.nav-tab,
.create-tab {
  display: flex;

  padding: 0.4em 1.2em;

  border-bottom: 2px solid var(--honey);
  border-bottom-left-radius: 24%;
  border-bottom-right-radius: 24%;

  text-align: center;
  align-items: center;
}

nav button {
  display: flex;
  color: var(--sinister);
  font-weight: 600;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  margin-top: 2px;
}

.logout {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  padding: 0.8rem;

  background: var(--cafe-creme);

  border-bottom: 2px solid var(--honey);
  border-bottom-left-radius: 24%;
  border-bottom-right-radius: 24%;
}
.logout button {
  display: flex;
  flex-direction: column;
  font-family: "Josefin Slab", serif;
  color: var(--sinister);
}

/* hover */
.admin-tab:hover,
.nav-tab:hover,
.create-tab:hover,
.logout:hover {
  background: var(--honey);
  color: var(--sinister);
}

.nav-tab:hover a {
  color: var(--moonless-night);
}

.logout:hover button {
  color: var(--sinister);
  cursor: pointer;
}

/* -------------------- Admin ----------------------- */

.admin-tabs {
  display: flex;
  flex-direction: row;
}

.admin-tab {
  display: flex;

  padding: 0.4em 1.4em;
  margin: 0px 2rem;

  border-top: none;
  border-bottom: 2px solid var(--honey);

  border-bottom-left-radius: 24%;
  border-bottom-right-radius: 24%;
}

/*-------------------- Responsive Design ----------------------*/
/* ------- desktop ------- */
@media only screen and (min-width: 900px) {
}

/* ------- tablet & mobile  -------- */
@media only screen and (max-width: 899px) {
  nav ul {
    width: 100%;
  }
  nav a {
    font-size: 1rem;
  }
}
